<template>
  <b-modal
    id="forgot-password-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @hidden="reset"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>Recupere a senha</span>
      </h2>
      <p>Digite o email utilizado para cadastro na plataforma</p>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <!-- SEND RESET LINK FORM -->
    <form @submit.prevent="submit" class="text-center default-form">
      <div class="form-group">
        <input type="email" class="form-control" v-model.trim="$v.form.email.$model" placeholder="email@email.com.br" />
      </div>
      <div class="text-center mt-3">
        <Button
          type="submit"
          class="btn btn-primary"
          :disabled="$v.$invalid"
          :loading="loader"
        >
          Enviar email
        </Button>
      </div>
    </form> <!-- END SEND RESET LINK -->
  </b-modal>
</template>

<script>

import AuthService from '@/modules/auth/services/auth-service'
import { parseErrorResponseToArray } from '@/utils'
import { required, email } from 'vuelidate/lib/validators'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'

export default {
  name: 'ForgotPasswordModal',
  components: {
    AlertWraper,
    Button
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false,
      form: {
        email: ''
      }
    }
  },
  validations () {
    return {
      form: {
        email: { required, email }
      }
    }
  },
  methods: {
    /**
     * Send reset email
     */
    async submit () {
      this.loader = true
      this.content = null
      try {
        await AuthService.sendResetPasswordEmail(this.form.email)
        this.form.email = ''
        this.content_type = 'success'
        this.content = 'Enviamos um email com as instruções para troca de senha'
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
        this.content_type = 'error'
      }
      this.loader = false
    },
    reset () {
      this.form.email = ''
    }
  }
}
</script>
