<template>
  <b-modal
    id="registration-modal"
    ref="registration-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @hide="removeDisableCopy"
    @shown="shown"
    @hidden="reset"
  >
    <template v-slot:default>

      <!-- REGISTRATION FORM -->
      <div v-if="localStep === 'registration'">
        <div class="text-center">
          <h2 class="highlighted-color1">
            <span>Cadastro</span>
          </h2>
          <p>Insira seus dados abaixo</p>
        </div>
        <AlertWraper
          :content="content"
          :type="content_type"
        />
        <form @submit.prevent="submit" class="default-form" ref="authForm">
          <div class="form-group mb-3">
            <label class="form-label fw-bold">Nome completo*</label>
            <input type="text" class="form-control" placeholder="Nome completo" v-model.trim="$v.form.name.$model" />
          </div>
          <div class="form-group mb-3">
            <label class="form-label fw-bold">CPF*</label>
            <the-mask class="form-control" placeholder="000.000.000-00" mask="###.###.###-##" v-model.trim="$v.form.document.$model" />
          </div>
          <div class="form-group mb-3">
            <label class="form-label fw-bold">E-mail*</label>
            <input type="email" class="form-control" placeholder="email@email.com.br" v-model.trim="$v.form.email.$model" />
          </div>
          <div class="form-group mb-3">
            <label class="form-label fw-bold">Confirme o e-mail*</label>
            <input type="email" class="form-control" placeholder="email@email.com.br" v-model.trim="$v.form.email_confirmation.$model" />
          </div>
          <!-- MESSAGES -->
          <div
            class="mb-2 error-message-card text-color2 font-size-small px-3 py-2"
            v-if="!isEmailInputConfirmed && form.email && form.email_confirmation"
          >
            <p
              class="mb-0"
            >
              Os endereços de e-mail precisam ser iguais.
            </p>
          </div> <!-- END MESSAGES -->
          <div class="form-group mb-3">
            <label class="form-label fw-bold">Celular*</label>
            <the-mask class="form-control" placeholder="(00) 00000-0000" mask="(##) #####-####" v-model.trim="$v.form.phone.$model" />
          </div>
          <div class="form-group mb-3" v-if="1 == 2">
            <label class="form-label fw-bold">Código de indicação</label>
            <input type="text" class="form-control" placeholder="" v-model.trim="form.referral_code" />
          </div>
          <div class="form-group mb-3">
            <label class="form-label fw-bold">Senha*</label>
            <input type="password" class="form-control" placeholder="Insira sua senha" v-model.trim="$v.form.password.$model" autocomplete="new-password" />
          </div>
          <div class="form-group mb-3">
            <label class="form-label fw-bold">Confirme a senha*</label>
            <input type="password" class="form-control" placeholder="Confirme sua senha" v-model.trim="$v.form.password_confirmation.$model" autocomplete="new-password" />
          </div>
          <div class="form-group mb-3">
            <label class="form-label fw-bold">Você é?*</label><br/>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model.trim="$v.form.type.$model" id="registration-type-advertiser" value="ADVERTISER">
              <label class="form-check-label" for="registration-type-advertiser">Anunciante</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model.trim="$v.form.type.$model" id="registration-type-agency" value="AGENCY">
              <label class="form-check-label" for="registration-type-agency">Agência</label>
            </div>
          </div>

          <div class="mt-3" v-if="form.type">
            <div class="text-center">
              <h3 class="highlighted-color1">
                <span>Informações jurídicas</span>
              </h3>
            </div>
            <div class="form-group mb-3">
              <label class="form-label fw-bold">Razão Social*</label>
              <input type="text" class="form-control" placeholder="Razão social da empresa" v-model.trim="$v.form.company_name.$model" />
            </div>
            <div class="form-group mb-3">
              <label class="form-label fw-bold">Nome Fantasia*</label>
              <input type="text" class="form-control" placeholder="Nome fantasia da empresa" v-model.trim="$v.form.company_fantasy_name.$model" />
            </div>
            <div class="form-group mb-3">
              <label class="form-label fw-bold">CNPJ/CPF*</label>
              <the-mask class="form-control" placeholder="CNPJ/CPF*" :mask="['###.###.###-##', '##.###.###/####-##']" v-model.trim="$v.form.company_document.$model" />
            </div>
            <template v-if="form.type === 'ADVERTISER'">
              <div class="form-group mb-3">
                <label class="form-label fw-bold">Website*</label>
                <div class="input-group mb-3">
                  <span class="input-group-text">https://</span>
                  <input type="text" class="form-control" placeholder="empresa.com.br" v-model.trim="$v.form.website.$model" />
                </div>
              </div>
              <div class="form-group mb-3">
                <label class="form-label fw-bold">Categoria*</label>
                <div v-if="categoriesLoader">
                  <Loader
                    :size="'small'"
                    :align="'start'"
                  />
                </div>
                <div class="custom-select" v-else>
                  <select class="form-control" v-model.trim="$v.form.category_id.$model">
                    <option disabled="true" selected="true">Selecione</option>
                    <template v-if="categories.length > 0">
                      <option v-for="(category, index) in categories" :key="index" :value="category.id">{{ category.name }}</option>
                    </template>
                  </select>
                </div>
              </div>
            </template>
            <div class="mb-3">
              <div class="position-relative">
                <LoaderOverlay
                  size="big"
                  v-if="zipcodeLoader"
                />
                <div
                  class="d-lg-flex mb-3"
                >
                  <div class="col-lg-4 pl-0">
                    <div class="form-group">
                      <label class="form-label fw-bold">CEP*</label>
                      <TheMask
                        :mask="'#####-###'"
                        v-model.trim="$v.form.address.zipcode.$model"
                        placeholder=""
                        class="form-control"
                        @change.native="getZipcode"
                      />
                    </div>
                  </div>
                  <div class="col-lg-8 px-0 ps-3">
                    <div class="form-group">
                      <label class="form-label fw-bold">Logradouro*</label>
                      <input type="text" class="form-control" v-model.trim.trim="$v.form.address.street.$model" placeholder="" :readonly="readonlyForm" />
                    </div>
                  </div>
                </div>
                <div
                  class="d-lg-flex mb-3"
                >
                  <div class="col-lg-4 pl-0">
                    <div class="form-group">
                      <label class="form-label fw-bold">Número*</label>
                      <input type="text" class="form-control" v-model.trim.trim="$v.form.address.number.$model" placeholder="" />
                    </div>
                  </div>
                  <div class="col-lg-4 px-3">
                    <div class="form-group">
                      <label class="form-label fw-bold">Complemento</label>
                      <input type="text" class="form-control" v-model.trim.trim="form.address.complement" placeholder="" />
                    </div>
                  </div>
                  <div class="col-lg-4 px-0">
                    <div class="form-group">
                      <label class="form-label fw-bold">Bairro*</label>
                      <input type="text" class="form-control" v-model.trim.trim="$v.form.address.neighborhood.$model" placeholder="" :readonly="readonlyForm" />
                    </div>
                  </div>
                </div>
                <div
                  class="d-lg-flex"
                >
                  <div class="col-lg-5 pe-3">
                    <div class="form-group">
                      <label class="form-label fw-bold">Cidade*</label>
                      <input type="text" class="form-control" v-model.trim.trim="$v.form.address.city.$model" placeholder="" readonly />
                    </div>
                  </div>
                  <div class="col-lg-5 pe-3">
                    <div class="form-group">
                      <label class="form-label fw-bold">Estado*</label>
                      <input type="text" class="form-control" v-model.trim.trim="$v.form.address.state.$model" placeholder="" readonly />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" v-model.trim="$v.form.terms.$model" id="terms">
              <label class="form-check-label" for="terms">Li e concordo com os <router-link :to="{ name: 'statics.terms' }" target="_blank">Termos de Uso e Política de Privacidade</router-link>.</label>
            </div>
          </div>

          <div class="text-center mt-3">
            <!-- MESSAGES -->
            <div
              class="mb-2 alert-message-card text-color2 font-size-small px-3 py-2"
              v-if="loader && form.type === 'ADVERTISER'"
            >
              <p
                class="mb-0"
              >
                Essa ação pode levar alguns minutos.
              </p>
            </div> <!-- END MESSAGES -->
            <Button
              type="submit"
              class="btn btn-primary"
              :disabled="$v.$invalid || !isEmailInputConfirmed"
              :loading="loader"
            >
              Cadastrar
            </Button>
          </div>
        </form>
      </div> <!-- END REGISTRATION FORM -->

      <!-- CONFIRMATION FORM -->
      <div v-if="localStep === 'verification'">
        <div class="text-center">
          <h2>Confirmação</h2>
          <p>
            Enviamos um código para<br/>
            <strong>{{ confirmationEmail }}</strong>
          </p>
        </div>
        <AlertWraper
          :content="content"
          :type="content_type"
        />
        <div class="mt-3">
          <form @submit.prevent="verify" class="text-center default-form">
            <div class="form-group">
              <label class="form-label fw-bold">Insira o código enviado</label>
              <input type="text" class="form-control" v-model.trim.trim="$v.confirmation.code.$model" placeholder="" />
            </div>
            <p class="font-size-small mt-2 col-lg-10 mx-lg-auto">Seu código pode estar na caixa de spam, lembre-se de checá-la buscando pelo email no-reply@adsplayx.com.br</p>
            <div class="text-center mt-3">
              <a href="#" @click.prevent="reSendCode" class="mb-3 d-block">
                <template v-if="isResendingCode">
                  <Loader
                    :size="'small'"
                    :align="'start'"
                  />
                </template>
                <template v-else>
                  Não recebeu? <br>Enviar código novamente
                </template>
              </a>
              <Button
                type="submit"
                class="btn btn-primary"
                :disabled="$v.$invalid"
                :loading="loader"
              >
                Confirmar
              </Button>
            </div>
          </form>
        </div>
      </div> <!-- END CONFIRMATION FORM -->
    </template>

  </b-modal>
</template>

<script>

import AuthService from '@/modules/auth/services/auth-service'
import CategoriesService from '@/modules/categories/services/categories-service'
import { getZipcodeData, parseErrorResponseToArray } from '@/utils'
import { required, email } from 'vuelidate/lib/validators'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import LoaderOverlay from '@/components/common/Loader/LoaderOverlay'
import { TheMask } from 'vue-the-mask'

export default {
  name: 'RegistrationModal',
  props: ['step', 'userEmail'],
  components: {
    AlertWraper,
    Loader,
    LoaderOverlay,
    Button,
    TheMask
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      errors: null,
      loader: false,
      zipcodeLoader: false,
      categoriesLoader: false,
      categories: [],
      localStep: 'registration',
      confirmation: {
        code: '',
        email: ''
      },
      isResendingCode: false,
      readonlyForm: true,
      form: {
        name: '',
        email: '',
        email_confirmation: '',
        document: '',
        password: '',
        password_confirmation: '',
        phone: '',
        type: '',
        company_name: '',
        company_fantasy_name: '',
        company_document: '',
        category_id: '',
        website: '',
        referral_code: '',
        terms: '',
        address: {
          zipcode: '',
          street: '',
          number: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: ''
        }
      }
    }
  },
  validations () {
    if (this.localStep === 'registration') {
      const obj = {
        form: {
          name: { required },
          email: { required, email },
          email_confirmation: { required, email },
          document: { required },
          password: { required },
          password_confirmation: { required },
          phone: { required },
          type: { required },
          company_name: { required },
          company_fantasy_name: { required },
          company_document: { required },
          address: {
            zipcode: { required },
            street: { required },
            number: { required },
            neighborhood: { required },
            city: { required },
            state: { required }
          },
          terms: { required }
        }
      }
      if (this.form.type === 'ADVERTISER') {
        obj.form.website = { required }
        obj.form.category_id = { required }
      }
      return obj
    } else if (this.localStep === 'verification') {
      return {
        confirmation: {
          code: { required }
        }
      }
    }
    return {}
  },
  created () {
    if (this.step) this.localStep = this.step
  },
  watch: {
    step (newStep) {
      this.localStep = newStep
    },
    userEmail (email) {
      this.confirmation.email = email
    },
    'form.type': function (newValue) {
      if (newValue === 'ADVERTISER' && this.categories.length === 0) this.getCategories()
    }
  },
  computed: {
    confirmationEmail () {
      return this.userEmail ? this.userEmail : this.confirmation.email
    },
    /**
     * Check if emails inputs are equal
     */
    isEmailInputConfirmed () {
      return this.form.email === this.form.email_confirmation
    }
  },
  methods: {
    /**
     * Disable copy paste
     */
    disableCopyPaste () {
      // Prevent ctrl c + ctrl v
      this.$refs.authForm.addEventListener('copy', (event) => {
        event.clipboardData.setData('text/plain', '')
        event.preventDefault()
      })
    },
    /**
     * Get categories for registration
     */
    async getCategories () {
      this.content = null
      this.categoriesLoader = true
      try {
        const categories = await CategoriesService.getCategories({ type: 'ADVERTISER' })
        this.categories = categories.data
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
        this.content_type = 'error'
      }
      this.categoriesLoader = false
    },
    /**
     * Get zipcode data given a zipcode
     */
    async getZipcode () {
      this.zipcodeLoader = true
      this.content = null
      try {
        const address = await getZipcodeData(this.form.address.zipcode)
        this.$v.form.address.street.$model = address.street
        this.$v.form.address.neighborhood.$model = address.neighborhood
        this.$v.form.address.city.$model = address.city
        this.$v.form.address.state.$model = address.state

        this.readonlyForm = !!address.street
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
        this.content_type = 'error'
      }
      this.zipcodeLoader = false
    },
    /**
     * Remove disable copy and paste
     */
    removeDisableCopy () {
      // Remove listener to prevent ctrl + c
      this.$refs.authForm.removeEventListener('copy', this.disableCopyPaste)
    },
    /**
     * Resend code
     */
    async reSendCode () {
      this.isResendingCode = true
      this.content = null
      try {
        await AuthService.resendConfirmationCode({ email: this.confirmation.email })
        this.content_type = 'success'
        this.content = 'Um novo código foi enviado para o seu email. Digite o código enviado ao seu email no campo abaixo'
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
        this.content_type = 'error'
      }
      this.isResendingCode = false
    },
    /**
     * Reset form
     */
    reset () {
      // this.form.name = ''
      // this.form.email = ''
      // this.form.document = ''
      // this.form.password = ''
      // this.form.password_confirmation = ''
      // this.form.phone = ''
      // this.form.type = ''
      // this.form.website = ''
      // this.form.category_id = ''
      // this.form.company_name = ''
      // this.form.company_document = ''
      // this.form.address.zipcode = ''
      // this.form.address.street = ''
      // this.form.address.number = ''
      // this.form.address.complement = ''
      // this.form.address.neighborhood = ''
      // this.form.address.city = ''
      // this.form.address.state = ''

      this.localStep = 'registration'
      this.$emit('closed-modal')
    },
    /**
     * On modal shown
     */
    shown () {
      if (this.step) this.localStep = this.step
      if (this.userEmail) this.confirmation.email = this.userEmail

      window.dataLayer.push({
        event: 'reg-init',
        event_category: 'open-modal',
        event_label: 'Cadastre-se agora!'
      })

      // Checks for referral code
      const code = window.localStorage.getItem('referral')
      if (code) {
        this.form.referral_code = code
      } else {
        this.form.referral_code = ''
      }

      // Disable copy paste
      this.disableCopyPaste()
    },
    /**
     * Submit registration form
     */
    async submit () {
      this.loader = true
      this.content = null
      try {
        const form = Object.assign({}, this.form)
        if (form.website) form.website = `https://${form.website.replace('https://', '').replace('http://', '')}`
        const response = await AuthService.register(form)
        this.confirmation.email = response.email
        this.localStep = 'verification'
        window.localStorage.removeItem('referral')
        window.dataLayer.push({
          event: 'reg-finish',
          event_category: 'form',
          event_label: 'Cadastrar'
        })
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
        this.content_type = 'error'
        document.getElementById('registration-modal').scrollTop = 0
      }
      this.loader = false
    },
    /**
     * Send verification code for validation
     */
    async verify () {
      this.loader = true
      this.content = null
      try {
        await AuthService.verifyEmail({
          code: this.confirmation.code,
          email: this.confirmationEmail
        })
        location.reload()
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
        this.content_type = 'error'
      }
      this.loader = false
    }
  }
}
</script>
